<template>
    <div>
     <v-text-field
        v-model="rechargeramount"
        style="margin: 0 auto; height: 60px"
        label="Enter Amount"
      />
      <div style="width: 100%; float: left">
        <v-btn color="primary" :disabled="isvButtonDisabled" style="width: 100%; margin: 0 auto" @click="submit"
          >Submit</v-btn
        >
      </div>
      <div v-html="formHtml" ></div>
    </div>
    
   

  </template>
  
  <script>
 
  export default {
    data() {
      return {
        provider: [],
        rechargeramount: "",
       isvButtonDisabled:false,
       formHtml: "",

      };
    },
    computed: {
      getprovider() {
        return this.$store.getters.operator;
      },
      rwidth() {
        return this.$store.getters.rwidth;
      },
      user() {
        return this.$store.getters.user;
      },
      clear() {
        return this.$store.getters.clear;
      },
      sortedproviver() {
        function compare(a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }
        let provider = this.provider;
        return provider.sort(compare);
      },
    },
    watch: {
      getprovider() {
        let vm = this;
      
      },
      clear() {
        this.cleardata();
      },
    },
    methods: {
      cleardata() {
        let vm = this;
      
        vm.rechargernumber = "";
     
      },
      errorimg(e) {
        e.target.src = require("../assets/logo_single.png");
      },
    
    
      submit() {
        debugger;
        let vm = this;
        if (vm.user.length == 0)
          return vm.$store.dispatch("notify", {
            vm: vm,
            message: "Authentication Required!",
            type: "warn",
          });
        
      
        if (vm.rechargeramount == "")
          return vm.$store.dispatch("notify", {
            vm: vm,
            message: "Recharge Amount Required",
            type: "error",
          });

       
      this.isvButtonDisabled = true;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,        
        Amount: vm.rechargeramount,       
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "CreateOrderForWEB",
          type: "sBPG",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.Status === "True") {
            const currentpath = window.location.href;           
      const expirationDays = 7;
      const date = new Date();
      date.setDate(date.getDate() + expirationDays);
      
      // Set cookie with path and expiration
      document.cookie = `SBPath=${currentpath};expires=${date.toUTCString()};path=/`;

        
            let message = data.Message;
            const container = document.createElement('div');
            container.innerHTML  = data.Data;
            document.body.appendChild(container.firstChild);  
  document.getElementById('autoSubmitForm').submit();
            //vm.$root.alert(message);     
            setTimeout(() => {
    document.getElementById('autoSubmitForm').remove();
  }, 1000);     
          }
          else {}
        })
        .catch(function (err) {
          vm.loader = false;
          this.isvButtonDisabled = false;
          
        }).finally(() => {
            this.isvButtonDisabled = false;          
        });        
      },
    },
    mounted() {
      let vm = this;
    
    },
  };
  </script>
  