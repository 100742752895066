<template>
  <div>
    <v-toolbar class="secondary" dense style="color: white; font-weight: bold">
      Ledger Report
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <download-excel
          :data="statementlist"
          name="StatementReport.xls"
          :fields="fields"
          style="height: 30px; margin-top: 9px; margin-right: 10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>
        <v-menu
          :close-on-content-click="false"
          v-model="menu1"
          lazy
          transition="scale-transition"
          offset-y
          style="float: right"
        >
          <input
            type="text"
            slot="activator"
            style="color: black"
            v-model="date1"
            readonly
            class="search"
            placeholder="From Date"
          />
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          v-model="menu2"
          lazy
          transition="scale-transition"
          offset-y
          style="float: right"
        >
          <input
            type="text"
            slot="activator"
            style="color: black"
            v-model="date2"
            readonly
            class="search"
            placeholder="To Date"
          />
          <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
        </v-menu>

        <v-btn
          class="success searchbtn"
          style="height: 34px; margin-left: 10px"
          @click="fetchstatement"
        >
          Search</v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      hide-actions
      :items="statementlist"
      :loading="loading"
    >
      <v-progress-linear
        slot="progress"
        color="blue"
        indeterminate
      ></v-progress-linear>
      
      <template slot="items" slot-scope="props">
        <td>
          {{
             props.item.date
             
            }}
          <!-- --{{ new Date(props.item.date) | moment("DD-MM-YYYY, h:mm:ss a") }} -->
        </td>
        <td>
          {{
           props.item.onlydate
             
            }}
          
        </td>
        <td>{{ props.item.openingbal }}</td>
        <td>{{ props.item.debit }}</td>
        <td>{{ props.item.credit }}</td>
        <td>{{ props.item.useramount }}</td>
    
        <td>{{ props.item.mobile }}</td>
        <td>{{ props.item.rechargeid }}</td>
        <td>{{ props.item.paytransferid }}</td>
        <td>{{ props.item.remark }}</td>
        <td  v-if="showRemark">{{ props.item.creditusername }}</td>
      </template>
      <template slot="footer">
                    <tr>
                        <td colspan="3" style="text-align: right; font-weight: bold;">Grand Total:</td>
                    
                        <td  style="text-align: left; font-weight: bold;">{{DrTotal}}</td>
                        <td  style="text-align: left; font-weight: bold;">{{CrTotal}}</td>
                       
                     
                    </tr>
                </template>
    </v-data-table>
  </div>
</template>

<script>
 import moment from "moment";
export default {
  components: {  },
  data() {
    return {
      modal: false,
      date1: new Date().toISOString().substr(0, 10),
      menu1: false,
      date2: new Date().toISOString().substr(0, 10),
      menu2: false,
      loading: false,
      headers: [
        { text: "Date & Time", value: "date" },
        { text: "Date", value: "onlydate" },
        { text: "Opening Balance", value: "openingbal" },
        { text: "Debit Amount", value: "debit" },
        { text: "Credit Amount", value: "credit" },
        { text: "Closing Amount", value: "useramount" },    
        { text: "Account Number", value: "mobile" },
        { text: "Transaction ID", value: "rechargeid" },
        { text: "Payment Transfer ID", value: "paytransferid" },
        { text: "Remark", value: "remark" },
       
      ],
      fields: {
        "Date & Time": "date",
        "Date": "onlydate",
      OpeningBalance:"openingbal",
        Debit: "debit",
        Credit: "credit",
        ClosingBalance: "useramount",
        "Payment Type ID": "paymenttypeid",      
        "Account Number": "mobile",
        "Transaction ID": "rechargeid",
        "Payment Transfer ID": "paytransferid",
        Remark: "remark",
       
      },
      statementlist: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    showRemark() {
    debugger;
      let vm = this;
    return vm.user.Type == "Distributor" || vm.user.Type == "MasterDistributor"; // or whatever condition you need
  },
    OpenTotal() {
            return this.statementlist.reduce((total, item) => total + item.openingbal, 0);
        },
        CrTotal() {
          return this.statementlist.reduce((total, item) => total + (item.credit ?? 0), 0);//  return this.table.reduce((total, item) => total + item.credit, 0);
        },
        DrTotal() {
          return this.statementlist.reduce((total, item) => total + (item.debit ?? 0), 0); //return this.table.reduce((total, item) => total + item.debit, 0);
        },
        ClosingTotal() {
            return this.statementlist.reduce((total, item) => total + item.useramount, 0);
        },
  },
  methods: {

    showheaders() {
    debugger;
    if(this.showRemark)
    {this.headers = this.headers.concat({ text: "Credit Username", value: "creditusername" });}
    else{ this.headers;}
    return  this.headers;
  },
    fetchstatement() {
       let vm = this;
       if (vm.user.length == 0) return;
       vm.loading = true;
      // let doc =
      //   "?Mob=" +
      //   vm.user.phone +
      //   "&pin=" +
      //   vm.user.pin +
      //   "&StartDate=" +
      //   vm.date1 +
      //   "+23%3A15%3A30&EndDate=" +
      //   vm.date2 +
      //   "+23%3A15%3A30&Cmd=Statement&source=ANDROID";
      // vm.$store
      //   .dispatch("saxios", {
      //     data: doc,
      //   })
      //   .then(function (res) {
      //     vm.pushstatement(res.data);
      //   })
      //   .catch(function (error) {
      //     vm.$store.dispatch("notify", {
      //       vm: vm,
      //       message: error,
      //       type: "warn",
      //     });
      //   });

      let doc = {
          MobileNo: vm.user.phone,
          pinNo: vm.user.pin,        
          StartDate: vm.date1,
          EndDate: vm.date2,         
        };
        vm.dialog = true;
        vm.$store
          .dispatch("axios", {
            url: "Statement",
            data: doc,
          })
          .then(function ({ data }) {
            vm.dialog = false;
            if (data.Data) {
              vm.pushstatement(data.Data);
            }
          })
          .catch(function (error) {
            console.log( error.message);
            vm.notification("error", error.message);
            vm.dialog = false;
          });
    },
    pushstatement(datas) {
      debugger;
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let credit = 0;
        let debit = 0;
        if (data.Type == "D") {
          debit = data.Amount;
        } else {
          credit = data.Amount;
        }

      
        let doc = {
          date:moment(new Date(parseInt(data.CreateDate.replace("/Date(", "").replace(")/", "")))).format("DD-MM-YYYY, h:mm:ss a"),
          onlydate:moment(new Date(parseInt(data.CreateDate.replace("/Date(", "").replace(")/", "")))).format("DD-MM-YYYY"),
          remark: data.Remark,
          paymenttype: data.PaymentType,
          debit: debit,
          credit: credit,
          useramount: data.UserAmount,
          paymenttypeid: data.PaymentTypeId,
          username: data.UserName,
          mobile:data.MobileNo,
          rechargeid: data.RechargeId,
          paytransferid: data.PayTrfId === 0 ? "" : data.PayTrfId,
          openingbal:data.OpeningBal,
          id:data.Id,
          creditusername : data.PayTrfId === 0 ? "" : data.PayTransferInfo.CreditClientName && data.Type === "D" ? data.PayTransferInfo.CreditClientName : "",
        };
        
      
        if (doc.remark) {
  doc.remark = doc.remark.replace("Subpaisa", "Payment Gateway").replaceAll("Recharge","Transaction").replace("ANDROID","System");
}

if (doc.mobile) {
  doc.mobile ="A\C No:" + data.MobileNo;
}
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    },
  },
  mounted() {
    let vm = this;
    vm.showheaders();
    vm.fetchstatement();
  },
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
