<template>
  <div>
    <div class="main pt-3" :style="{ flexDirection: position ? 'column' : 'row' }">
      <div class="left pb-3" :style="{ width: position ? '100%' : '350px' }">
        <div class="left__content">
          <div class="form">
            <IMPS v-show="way == 'imps'" @data-changed="handleDataChange" @refresh="refresh"
              :saccountDetail="saccountDetail" />
            <Prepaid v-show="way == 'prepaid'" />
            <Postpaid v-show="way == 'postpaid'" />
            <Gas v-show="way == 'gas'" />
            <Electricity v-show="way == 'electricity'" />
            <DTHBooking v-show="way == 'dthbooking'" />
            <Broadband v-show="way == 'broadband'" />
            <Water v-show="way == 'water'" />
            <Dish v-show="way == 'dish'" />
            <Transfer v-show="way == 'transfer'" />
            <CreditCard v-show="way == 'creditcard'" />
            <SBLoadWallet v-show="way == 'loadwallet'" />
          </div>
        </div>
      </div>
      <div class="right" :class="position ? '' : 'pl-3'">
        <v-card>
          <v-toolbar dense dark class="secondary">
            <div v-show="change == true">Last 25 Transaction</div>
            <div v-show="change == false">Today Recharge</div>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" dense class="pt-0 mr-3" color="white" append-icon="search"
              placeholder="Search" hide-details></v-text-field>
            <v-toolbar-items>
              <download-excel :data="table" v-show="change" name="Last25_Recharge.xls" :fields="fields"
                style="height: 30px; margin-top: 9px; margin-right: 10px">
                <v-btn class="success" small>Export</v-btn>
              </download-excel>
              <download-excel :data="rtable" v-show="!change" name="Today_Recharge.xls" :fields="fields"
                style="height: 30px; margin-top: 9px; margin-right: 10px">
                <v-btn class="success" small>Export</v-btn>
              </download-excel>
              <v-btn @click="change = false" v-show="change" style="height: 30px" class="success">Today</v-btn>
              <v-btn v-show="!change" @click="change = true" style="height: 30px" class="success">Last 25</v-btn>
              <v-btn @click="refresh" icon>
                <v-icon style="margin-top: 0px" color="white">refresh</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-data-table :headers="headers" :items="rtable" hide-actions :search="search" :custom-sort="customSort"
            :loading="loading" class="customtable" v-if="change">
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
              <td :style="{ background: getbackground(props.item.Status) }">
                {{ props.item.Id }}
              </td>
              <td :style="{ background: getbackground(props.item.Status) }">
                {{ props.item.OrderId }}
              </td>
              <td :style="{ background: getbackground(props.item.Status) }">
                {{ props.item.PGUTR }}
              </td>
              <td :style="{ background: getbackground(props.item.Status) }">
                {{ props.item.Amount }}
              </td>
              <td :style="{ background: getbackground(props.item.Status) }">
                <v-btn small flat :class="checkstatus(props.item.Status)" style="width: 100%">{{
                  getStatusText(props.item.Status) }}</v-btn>
              </td>

              <td :style="{ background: getbackground(props.item.Status) }">
                {{
                  new Date(parseInt(props.item.CreatedDate))
                  | moment("DD-MM-YYYY, h:mm:ss a")
                }}
              </td>
              <td :style="{ background: getbackground(props.item.Status) }">{{ props.item.AgainstPaymentRequest }}</td>


              <td :style="{ background: getbackground(props.item.Status) }">
                <v-btn small style="width: 100%" class="primary" @click="print(props.item)">Print</v-btn>
              </td>
              <td :style="{ background: getbackground(props.item.Status) }">
                <v-btn small style="width: 100%" v-if="change && props.item.Status === 0"
                  @click="openremark(props.item)" class="warning">Check Status</v-btn>
              </td>
            </template>
          </v-data-table>

        </v-card>
      </div>
    </div>

    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" :sync="sync" />
    <Message v-if="message" :res="response" />
    <Complaint v-if="complaint" :id="tid" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";
import Prepaid from "../billpay/Prepaid.vue";
import Postpaid from "../billpay/Postpaid.vue";
import Gas from "../billpay/Gas.vue";
import Electricity from "../billpay/Electricity.vue";
import DTHBooking from "../billpay/DTHBooking.vue";
import Dish from "../billpay/Dish.vue";
import Transfer from "../billpay/Transfer.vue";
import Complaint from "../components/Complaint.vue";
import Broadband from "../billpay/Broadband.vue";
import Water from "../billpay/Water.vue";
import IMPS from "../billpay/IMPS.vue";
import CreditCard from "../billpay/CreditCard.vue";
import SBLoadWallet from "../billpay/SBLoadWallet.vue";

export default {
  components: {
    Prepaid,
    Postpaid,
    Gas,
    Electricity,
    DTHBooking,
    Dish,
    Progress,
    Preview,
    Message,
    Transfer,
    Complaint,
    Broadband,
    Water, IMPS, CreditCard, SBLoadWallet,
  },
  data() {
    return {
      way: "loadwallet",
      loading: false,
      active: 0,
      data: [],
      sync: [],
      progress: false,
      preview: false,
      message: false,
      response: "",
      change: true,
      params: "",
      search: "",
      type: "BP",
      tid: "",
      pagination: {},
      complaint: false,
      saccountDetail: {
        AccountName: "",
        Accountno: "",
        BankName: "",
        Ifsc: ""
      },

      tabs: [
        {
          name: "loadwallet",
          img: require("../assets/transfer.png"),
          way: "loadwallet"
        },
      ],
      fields: {
        "Recharge ID": "orderno",
        Service: "service",
        MobileNo: "mobile",
        Amount: "amount",
        Status: "status",
        "Transaction ID": "tid",
        Type: "type",
        "Opening Balance": "openbalance",
        "Closing Balance": "closebalance",
        Commission: "com",
        Surcharge: "surcharge",
        "Recharge Time": "time",
        Reason: "Reason",
      },
      headers: [
        { text: "Id", value: "Id" },
        { text: "OrderId", value: "OrderId" },
        { text: "PGUTR", value: "PGUTR" },
        { text: "Amount", value: "Amount" },
        { text: "Status", value: "Status" },
        { text: "CreatedDate", value: "CreatedDate" },
        { text: "AgainstPaymentRequest", value: "AgainstPaymentRequest" },
        { text: "Print", value: "action", align: "center" },
        { text: "CheckStatus", value: "action", align: "center" },
      ],

      table: [],
      shifttable: [],
      rtable: [],

    };
  },
  methods: {

    handleDataChange(data) {

    },
    getStatusText(status) {
      debugger;
      const statusMap = {
        '1': 'Fail',
        '2': 'Success',
        '0': 'Pending'
      };
      return statusMap[status] || 'Unknown';
    },
    checkstatus(status) {
      if (this.change) {
        // status = status.toLowerCase();
        if (status == 2) return "success";
        if (status == 0) return "grey";
        if (status == 0) return "warning";
        if (status == 1) return "error";
      }
      return "primary";
    },
    checkrstatus(status) {
      if (status == 2) return "success";
      if (status == 0) return "grey";
      if (status == 3) return "warning";
      if (status == 1) return "error";
      return "primary";
    },
    checkrbtn(status) {
      if (status == 2) return "Success";
      if (status == 0) return "Pending";
      if (status == 3) return "In Process";
      if (status == 1) return "Failure";
      return "Unknown";
    },
    getbackground(status) {
      if (this.change) {
        // status = status.toLowerCase();
        if (status == 2) return "#ffff";
        if (status == 0) return "#B0BEC5";
        if (status == 0) return "#FFF59D";
        if (status == 1) return "#EF9A9A";
      }
      return "#90CAF9";
    },
    getrbackground(status) {
      if (status == 2) return "#ffff";
      if (status == 0) return "#B0BEC5";
      if (status == 3) return "#FFF59D";
      if (status == 1) return "#EF9A9A";
      return "#90CAF9";
    },
    refresh() {
      let vm = this;
      if (vm.change) {
        vm.fetchdata();
      } else {
        vm.fetchrecharge();
      }
    },
    even(arr) {
      return arr.slice().sort(function (a, b) {
        return a.orderno - b.orderno;
      });
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index === "orderno") {
          if (isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    openremark(item) {
      debugger;
      let vm = this;
      vm.tid = item.OrderId;
      if (vm.tid == null) { return; }
      else {

        let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        OrderID: vm.tid,

      };

      vm.$store
        .dispatch("axiosdmrpost", {
          url: "PaymentStatusCheck",
          type: "sBPG",
          data: doc,
        })
        .then(function (res) {
          vm.loading = false;
          vm.$root.alert(res.data.Message);
        })
        .catch(function (error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
      }
    },
    handleresize() {
      let vm = this;
      let width = window.innerWidth - 330 - 293 + "px";
      vm.$store.commit("setrwidth", width);
    },
    fetchdata() {
      let vm = this;
      if (vm.user.length != 0) {
        let ldoc = {
          PinNo: vm.user.pin,
          MobileNo: vm.user.phone,
        };
        let url =
          vm.$root.loginType === "dmr"
            ? "AppSBPaymentReport25"
            : "AppSBPaymentReport25";
        vm.$store.dispatch("axiosget", {
          url: url,
          data: ldoc,
          way: "setlasttrans",
        });
      }
    },



    showtable() {

      let vm = this;
      vm.table = [];
      if (vm.report == undefined) return;
      for (let i = 0; i < vm.report.length; i++) {
        let doc = vm.report[i];
        let data = {
          Id: doc.Id,
          OrderId: doc.OrderId,
          PGUTR: doc.PGUTR,
          Amount: doc.Amount,
          Status: doc.Status,
          CreatedDate: doc.CreatedDate.replace("/Date(", "").replace(")/", ""),
          AgainstPaymentRequest: doc.AgainstPaymentRequest,
        };
        debugger;
        vm.table.push(data);
      }
      // vm.table = vm.even(vm.table)
      vm.loading = false;
    },



    showrtable(datas) {
      debugger;
      let vm = this;
      vm.rtable = [];
      for (let i = 0; i < datas.length; i++) {
        let doc = datas[i];
        let data = {
          Id: doc.Id,
          OrderId: doc.OrderId,
          PGUTR: doc.PGUTR,
          Amount: doc.Amount,
          Status: doc.Status,
          CreatedDate: doc.CreatedDate.replace("/Date(", "").replace(")/", ""),
          AgainstPaymentRequest: doc.AgainstPaymentRequest,
        };
        vm.rtable.push(data);
      }
      vm.loading = false;
    },
    fetchrecharge() {
      debugger;
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      var date = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,

      };

      vm.$store
        .dispatch("axiosdmrpost", {
          url: "AppSBPaymentReport25",
          type: "sBPG",
          data: doc,
        })
        .then(function (res) {
          vm.loading = false;
          vm.showrtable(res.data.Data);
        })
        .catch(function (error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
    dispatch() {
      let vm = this;
      // if (vm.user.length != 0) {
      //   let doc = { PinNo: vm.user.pin, MobileNo: vm.user.phone };
      //   let ldoc = {
      //     PinNo: vm.user.pin,
      //     MobileNo: vm.user.phone,
      //     PageSize: 25,
      //   };
      //   let url =
      //     vm.$root.loginType === "dmr"
      //       ? "LastTransactionDMR"
      //       : "LastTransaction";
      //   vm.$store.dispatch("axiosget", {
      //     url: url,
      //     data: ldoc,
      //     way: "setlasttrans",
      //   });
      //   vm.$store.dispatch("operator", { data: doc });
      //   vm.$store.dispatch("getbalance");
      // }
    },

    print(item) {
      debugger;
      let vm = this;
      var status = item.status;
      if (!vm.change) {
        status = vm.checkrbtn(item.status);
      }
      var date = vm.$moment(parseInt(item.time)).format("DD-MM-YYYY, h:mm a");
      var tid = item.tid;
      if (tid == null && tid == undefined) {
        tid = "-";
      }
      let splitArray = item.Reason.split(',');
      let resdetail = splitArray[0];
      let Acresdetail = resdetail.split(':');
      let Acountresdetail = Acresdetail[1].split('-');
      let Name = Acountresdetail[0].split('$').join(' ');
      let ifsc = Acountresdetail[2];
      let bankname = Acountresdetail[3].split('$').join(' ');

      var mywindow = window.open("");
      var head =
        "<html><head><title>Print</title><style>.mainwidth{width:400px;min-height:650px;background:white;margin:0 auto;border-radius:5px;}.header{width:398px;margin:1px;float:left;height:30px;border:0px;background:transparent;font-size:1em;background:lightseagreen;color:white;}.table{padding:10px;width:100%;}.table tr td{padding-bottom:5px;padding-top:5px;border-bottom:1px solid lightgrey;}</style>";
      mywindow.document.write(head);
      mywindow.document.write("</head><body >");
      mywindow.document.write(
        '<body><div style="border:1px solid lightgrey" class="mainwidth"><button class="header">Transaction Receipt</button>'
      );
      mywindow.document.write('<table class="table">');
      mywindow.document.write(
        "<tr><td>Transaction ID</td><td>" + item.RechargeId + "</td></tr>"
      );
      mywindow.document.write("<tr><td>RRN Number</td><td>" + tid + "</td></tr>");
      mywindow.document.write("<tr><td>Benificiary Name</td><td>" + Name + "</td></tr>");
      mywindow.document.write("<tr><td>Bank Name</td><td>" + bankname + "</td></tr>");
      mywindow.document.write("<tr><td>IFSC Code</td><td>" + ifsc + "</td></tr>");
      mywindow.document.write("<tr><td>Account Number</td><td>" + item.mobile + "</td></tr>");
      mywindow.document.write(
        "<tr><td>Transaction Amount</td><td>" + item.amount + "</td></tr>"
      );
      mywindow.document.write(
        "<tr><td>Service Charge</td><td>" + item.surcharge + "</td></tr>"
      );
      mywindow.document.write(
        "<tr><td>Total Amount</td><td>" + item.amount + "</td></tr>"
      );
      mywindow.document.write("<tr><td>Date</td><td>" + date + "</td></tr>");

      mywindow.document.write("</div>");
      mywindow.document.write("</body></html>");
      mywindow.document.close();
      setTimeout(print, 1000);
      function print() {
        mywindow.print();
      }
    },
  },
  computed: {
    position() {
      let style = localStorage.position;
      if (style == "true") {
        return true;
      }
      return false;
    },
    getprofile() {
      return this.$store.getters.profile.Data;
    },
    report() {
      return this.$store.getters.lasttrans.Data;
    },
    user() {
      return this.$store.getters.user;
    },
    items() {
      return this.$store.getters.quickpay;
    },
    logo() {
      return this.$store.getters.logo;
    },
    details() {
      return this.$store.getters.details;
    },
  },
  watch: {
    report() {
      this.loading = true;
      this.showtable();
    },
    change() {
      if (!this.change) {
        this.fetchrecharge();
      }
    },
  },
  mounted() {
    let vm = this;
    vm.dispatch();
    if (vm.user.Type == "Admin") return vm.$router.push("/transaction");
    if (vm.user.Type == "MasterDistributor")
      return vm.$router.push("/transaction");
    if (vm.user.Type == "Distributor") return vm.$router.push("/transaction");
    vm.fetchrecharge();
    this.showtable();
    let height = window.innerHeight - 400;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
  },
};
</script>
<style>
.customtable .v-table__overflow {
  overflow-y: hidden !important;
}
</style>
<style scoped>
.main {
  width: 100%;
  display: flex;
  height: 100%;
}

.left {
  width: 350px;
}

.left__content {
  margin: 0 auto;
  width: 350px;
}

.form {
  float: left;
  padding: 15px;
  background: white;
  width: 100%;
}

.right {
  flex-grow: 1;
  max-height: calc(100vh - 200px) !important;
  overflow: hidden;
}
</style>