<template>
  <v-app>
    <v-toolbar
      class="secondary"
      dark
      fixed
      dense
      app
      :prominent="isTop"
      :extended="isTop"
    >
      <v-btn icon @click="$router.push('/')">
        <v-icon>dashboard</v-icon>
      </v-btn>
      <!-- <v-icon @click="changewidth" style="color:white">Main</v-icon>
      -->
      <v-toolbar-title
        >Welcome ({{ convertcase(user.UserName) || "Guest" }}) </v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- <v-btn flat dark v-if="path === 'recharge'" @click="changeMain('dmr')"
          >Goto DMR</v-btn
        >
        <v-btn flat dark v-else @click="changeMain('recharge')"
          >Goto Recharge</v-btn
        > -->
        <v-btn flat v-if="user.length != 0" dark>₹&nbsp;{{ balance }}</v-btn>
        <v-btn v-if="isPG" slot="activator" flat dark @click="$router.push('/loadwallet')">
            <v-icon>credit_card</v-icon>&nbsp;LoadWallet
          </v-btn>
        <v-menu offset-y>         
          <v-btn slot="activator" flat dark>
            <v-icon>person</v-icon>&nbsp;Account
          </v-btn>          
          <v-list>
            <v-list-tile @click="$router.push('/setting')">
              <v-list-tile>Profile</v-list-tile>
            </v-list-tile>
            <v-list-tile @click="logout">
              <v-list-tile>Log Out</v-list-tile>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-tabs
        v-model="active"
        color="secondary"
        dark
        slot="extension"
        slider-color="primary"
        v-if="isTop"
      >
        <v-tab
          v-for="(item, i) in menu"
          :key="'menu-' + i"
          v-show="checkmenu(item)"
          @click="$router.push(item.path)"
          ripple
          >{{ item.name }}</v-tab
        >
      </v-tabs>
    </v-toolbar>
    <marquee> <span>{{NewsDetail}}
   
    </span></marquee>
    <v-content
      style="background: #f0f0f0; overflow: auto !important; padding: 0px"
      class="main_content"
    >
      <LeftMenu :menu="menu"></LeftMenu>
      <v-container fluid style="overflow: auto !important">
        <Progress v-if="check" />
        <router-view v-else path="/dashboard"></router-view>
        <vue-snotify></vue-snotify>
      </v-container>
    </v-content>
    <v-footer class="secondary">
      <v-spacer></v-spacer>
      <v-card-actions class="justify-center" style="color: white">
        &copy; 2019 - Developed By
        <strong>&nbsp;Payinpayout.</strong>
        &nbsp;v1.00
      </v-card-actions>
    </v-footer>
  </v-app>
</template>

<script>
import Progress from "../components/Progress.vue";
import LeftMenu from "../components/LeftMenu";

export default {
  name: "App",
  
  data() {
    return {
      drawerwidth: 220,
      drawer: false,
      way: "billpay",
      active: -1,
      NewsDetail: "",
      check: true,
      isTop: false,
      isPG:false,
      menu: [

      {
          name: "Dashboard",
          img: this.isTop
            ? require("../assets/rupee.png")
            : require("../assets/dashboard.svg"),
          way: "dashboard",
          path: "/",
        },
        {
          name: "Load Wallet",
          img: this.isTop
            ? require("../assets/loadwallet.png")
            : require("../assets/loadwallet.png"),
          way: "loadwallet",
          path: "/loadwallet",
        },
       
        {
          name: "Transfer",
          img: this.isTop
            ? require("../assets/rupee.png")
            : require("../assets/rupee.png"),
          way: "billpay",
          path: "/transfer",
        },
        
        {
          name: "Transfer Bulk",
          img: this.isTop
            ? require("../assets/rupee.png")
            : require("../assets/rupee.png"),
          way: "impsbulk",
          path: "/bulktransfer",
        },
        {
          name: "Validation Bulk",
          img: this.isTop
            ? require("../assets/bankvalidation.jpg")
            : require("../assets/bankvalidation.jpg"),
          way: "validationbulk",
          path: "/bulkvalidation",
        },
        {
          name: "CreditCardBillpay",
          img: this.isTop
            ? require("../assets/crcard.png")
            : require("../assets/crcard.png"),
          way: "creditcard",
          path: "/creditcard",
        },
        {
          name: "ElectricityBillPay",
          img: this.isTop
            ? require("../assets/electricity.png")
            : require("../assets/electricity.png"),
          way: "electricity",
          path: "/electricity",
        },
        // {
        //   name: "DMT Transfer",
        //   img: this.isTop
        //     ? require("../assets/rupee.png")
        //     : require("../assets/rupee.png"),
        //   way: "dmr1",
        //   path: "/dmr1",
        // },

        // {
        //   name: "DMT Transfer 1",
        //   img: this.isTop
        //     ? require("../assets/rupee.png")
        //     : require("../assets/rupee.png"),
        //   way: "dmrps",
        //   path: "/dmrps",
        // },
        {
          name: "Imps / Payout 2",
          img: this.isTop
            ? require("../assets/rupee.png")
            : require("../assets/rupee.png"),
          way: "dmr2",
          path: "/dmr2",
        },
        {
          name: "Imps / Payout 3",
          img: this.isTop
            ? require("../assets/rupee.png")
            : require("../assets/rupee.png"),
          way: "dmr3",
          path: "/dmr3",
        },
        {
          name: "AEPS 2",
          img: require("../assets/aeps.png"),
          way: "aeps-2",
          path: "/aeps-2",
        },
        {
          name: "Micro ATM Report",
          img: this.isTop
            ? require("../assets/enquiry.svg")
            : require("../assets/childlist.png"),
          way: "micro-atm-report",
          path: "/micro-atm-report",
        },
        {
          name: "Bill Utility",
          img: this.isTop
            ? require("../assets/recharge.svg")
            : require("../assets/recharge.png"),
          way: "billutility",
          path: "/billutility",
        },
        {
          name: "Downline",
          img: this.isTop
            ? require("../assets/downline.png")
            : require("../assets/downline.png"),
          way: "downline",
          path: "/downline",
        },
        {
          name: "Transaction Inquiry",
          img: this.isTop
            ? require("../assets/enquiry.svg")
            : require("../assets/childlist.png"),
          way: "inquiry",
          path: "/inquiry",
        },
        {
          name: "Topup Request",
          img: this.isTop
            ? require("../assets/request.svg")
            : require("../assets/request.png"),
          way: "request",
          path: "/request",
        },
        {
          name: "Balance Transfer",
          img: this.isTop
            ? require("../assets/transfer.svg")
            : require("../assets/transaction.png"),
          way: "transaction",
          path: "/transaction",
        },
        {
          name: "Last Transactions",
          img: this.isTop
            ? require("../assets/lasttransaction.svg")
            : require("../assets/success.svg"),
          way: "lasttransaction",
          path: "/lasttransaction",
        },
        {
          name: "Ledger Reports",
          img: this.isTop
            ? require("../assets/report.svg")
            : require("../assets/report.png"),
          way: "ledgerreport",
          path: "/ledgerreport",
        },
        {
          name: "Reports",
          img: this.isTop
            ? require("../assets/report.svg")
            : require("../assets/report.png"),
          way: "report",
          path: "/report",
        },
        // {
        //   name: "Charges",
        //   img: this.isTop
        //     ? require("../assets/commission.svg")
        //     : require("../assets/commission.png"),
        //   way: "commission",
        //   path: "/commission",
        // },
        // {
        //   name: "Offers",
        //   img: require("../assets/discount.svg"),
        //   img: this.isTop
        //     ? require("../assets/discount.svg")
        //     : require("../assets/offer.png"),
        //   way: "offer",
        //   path: "/offer",
        // },
        {
          name: "Complaints",
          img: this.isTop
            ? require("../assets/complaint.svg")
            : require("../assets/complaint.png"),
          way: "complaint",
          path: "/complaint",
        },
     
    

        // {
        //   name: "Outstanding",
        //   img: this.isTop
        //     ? require("../assets/outstanding.svg")
        //     : require("../assets/outstanding.png"),
        //   way: "outstanding",
        //   path: "/outstanding",
        // },
        // {
        //   name: "Settings",
        //   img: this.isTop
        //     ? require("../assets/usersettings.svg")
        //     : require("../assets/setting.png"),
        //   way: this.getsetting().replace("/", ""),
        //   path: this.getsetting(),
        // },
        // {
        //   name: "Supports",
        //   img: this.isTop
        //     ? require("../assets/support.svg")
        //     : require("../assets/support.png"),
        //   way: "support",
        //   path: "/support",
        // },
        {
          name: "Download App",
          img: this.isTop
            ? require("../assets/google-play.svg")
            : require("../assets/google.png"),
          way: "download",
          path: "/download",
        },
      ],
    };
  },
  beforeMount() {
    let vm = this;
    vm.$store.dispatch("getuser");
    if (vm.user.length != 0) {
      let doc = {
        PinNo: vm.user.pin,
        MobileNo: vm.user.phone,
      };
      vm.$store
        .dispatch("axios", {
          url: "Authentication",
          data: doc,
        })
        .then(function (res) {
          let doc = res.data;
          if (doc.Status == "True") {
            let data = doc.Data[0];
            let user = JSON.parse(localStorage.user);
            user = { ...user, ...data };
            localStorage.user = JSON.stringify(user);
            vm.$store.dispatch("getuser");
            vm.check = false;
          } else {
            vm.logout();
            vm.check = false;
          }
        })
        .catch(function () {
          vm.logout();
          vm.check = false;
        });

       
    } else {
      vm.check = false;
    }

  
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    path() {
      return this.$store.getters.way;
    },
    balance() {
      
      return this.$store.getters.balance;
    },
    items() {
      return this.$store.getters.quickpay;
    },
    logo() {
      return this.$store.getters.logo;
    },
    details() {
      return this.$store.getters.details;
    },
 
  
  },
  methods: {
    changeMain(way) {
      let vm = this;
      localStorage.loginType = way;
      vm.active = 0;
      vm.$store.commit("setway", way);
      vm.$router.push("/");
      location.reload();
    },
    checkmenu(item) {
      let vm = this;
      if (vm.user.Type == "Distributor" && item.name == "Transfer")  return false;
      if (vm.path === "recharge" && item.name === "DMR") return false;
      if (vm.path === "dmr" && item.name === "Recharge") return false;
      if (vm.user.Type == "Admin" && item.name == "Recharge") return false;
      if (vm.user.Type == "MasterDistributor" && item.name == "Recharge")
        return false;
      if (vm.user.Type == "Distributor" && item.name == "Recharge")
        return false;
        if (vm.user.Type == "Dealer" && item.name == "Balance Transfer") return false;
      if (vm.user.Type == "Dealer" && item.name == "Downline") return false;
      return true;
     
       
    },
    getsetting() {
      let user = localStorage.user;
      if (user) {
        return "/setting";
      }
      return "/account";
    },
    convertcase(value) {
      if (value != null) {
        value = value.toUpperCase();
      }
      return value;
    },
    logout() {
      localStorage.removeItem("user");
      window.location.reload();
    },
    changewidth() {
      let vm = this;
      if (vm.drawerwidth == 220) {
        let width = window.innerWidth - 330 - 153 + "px";
        vm.$store.commit("setrwidth", width);
        vm.drawerwidth = 70;
      } else {
        vm.drawerwidth = 220;
        let width = window.innerWidth - 330 - 303 + "px";
        vm.$store.commit("setrwidth", width);
      }
    },
    fetchdata() {
      let vm = this;
      if (vm.user.length != 0) {
        let ldoc = {
          PinNo: vm.user.pin,
          MobileNo: vm.user.phone,
          PageSize: 25,
        };
        let url =
          vm.$root.loginType === "dmr"
            ? "LastTransactionDMR"
            : "LastTransaction";
        vm.$store.dispatch("axiosget", {
          url: url,
          data: ldoc,
          way: "setlasttrans",
        });
      }
    }, openb2c(){
        let url = "http://admin.trusteasy.in/Admin/Files/Img_Circle_Operator/trusteasy.apk"
         var win = window.open(url, '_blank');
         win.focus();
      },

      GETPGStatus() {
        let vm = this;
      debugger;
        let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,      
      };

      vm.$store
        .dispatch("axiosdmrpost", {
          url: "SBUpiPaymentGatewayStatus",
          type: "sBPG",
          data: doc,
        })
        .then(function (res) {
        if(res.data.Status == "True")
        {
          debugger;
          vm.isPG = true;
        }
        else
        {
          vm.isPG = false;
        }
       
        })
        .catch(function (error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
      
    },
    GETNews() {
      let vm = this;
      // vm.NDetail ="";
      let doc = {
        MobileNo: vm.user.phone,
        pinNo: vm.user.pin      
      };
      vm.dialog = true;
      vm.$store
        .dispatch("axios", {
          url: "News",
          data: doc,
        })
        .then(function ({ data }) {
          vm.dialog = false;
          if (data.Data) {           
            vm.NewsDetail= data.Data[0].news;
            
          }
        })
        .catch(function (error) {
          vm.notification("error", error.message);
          vm.dialog = false;
        });
       
    },
    fetchsettings() {
      let vm = this;
      let domain = vm.getdomainname(window.location.hostname);
      let doc = { DomainName: domain };
      vm.$store
        .dispatch("axios", { url: "WhiteLableDetails", data: doc })
        .then(function (res) {
          let doc = [];
          let value = res.data.Data;
          if (value == null) return;
          if (value.Details != null) {
            doc.details = value.Details;
          }
          if (value.BannerImages.LoginLogo != null) {
            doc.logo = value.BannerImages.LoginLogo;
          }
          if (value.BankDetails != null) {
            doc.bankdetails = value.BankDetails;
          }
          vm.$store.commit("setdetails", doc);
        })
        .catch(function () {});
    },
    openlink(link) {
      let vm = this;
      if (link != "/download") {
        vm.$router.push(link);
      } else {
        let win = window.open(vm.details.PlayStoreUrl, "_blank");
        win.focus();
      }
    },
    getdomainname(domain) {
      let split = domain.split(".");
      if (split.length > 2) {
        domain = split[1] + "." + split[2];
      }
      return domain;
    },
  },
  components: {
    Progress,
    LeftMenu,
  },
  mounted() {    
    var nd = "test";
    let vm = this;
    vm.menu.forEach((item, i) => {
      if (item.path === vm.$route.path) vm.active = i;
    });
    if (vm.user.phone === undefined)
      return vm.$store.commit("setway", "website");
    vm.way = vm.$route.name;
    vm.$store.dispatch("getbalance");
    let interval = localStorage.interval;
    if (interval == null) {
      interval = 30;
    }
    setInterval(function () {
      if (vm.way == "billpay") {
        vm.fetchdata();
      }
    }, 1 * interval * 1000);
    let height = window.innerHeight - 200;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
    vm.GETNews();
 
    vm.GETPGStatus();
    //  vm.fetchsettings();
  },
};
</script>

<style>
table tr td {
  white-space: nowrap;
  padding: 0px !important;
  height: 35px !important;
  padding-left: 10px !important;
  padding-right: 15px !important;
}
table tr th {
  padding: 0px !important;
  height: 20px !important;
  padding-left: 10px !important;
  padding-right: 15px !important;
}
table.v-table tbody td {
  border-right: 1px solid lightgray;
}
.v-table__overflow {
  overflow: auto !important;
}
.custom_primary {
  background: #080f54 !important;
}
.navbar_item {
  height: 50px !important;
  margin-left: 10px;
  padding-top: 5px !important;
}
.navbar_active {
  height: 50px !important;
  margin-left: 10px;
  padding-top: 5px !important;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.main_content {
  max-height: calc(100vh - 84px) !important;
}
.application--wrap {
  min-height: calc(100vh - 48px) !important;
}
</style>
