<template>
    <div>
  
      <SBLoadWalletMain v-show="way == 'SBLoadWalletMain'" />
      
      <Progress v-if="progress" />
      <Preview v-if="preview" :data="data" :params="params" />
      <Message v-if="message" :res="response" />
    </div>
  </template>
  <script>
  import SBLoadWalletMain from "./SBLoadWalletMain.vue";  
  import Progress from "../components/Progress.vue";
  import Preview from "../components/Preview.vue";
  import Message from "../components/Message.vue";
  export default {
    data() {
      return {
        data: [],
        type: "BP",
        progress: false,
        preview: false,
        message: false,
        response: "",
        params: "",
        tabs: [
        
         
        ],
        way: "SBLoadWalletMain"
      };
    },
    methods: {
      fetchdata() {
        this.$parent.fetchdata;
      }
    },
    components: {
        SBLoadWalletMain,
      Progress,
      Preview,
      Message
    }
  };
  </script>
  